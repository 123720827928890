import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import  { environment } from '../../environments/environment'
const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':  'Api-Key '+ environment.api_key
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http:HttpClient
  ) { }
  checkEmail(email:string) {
    return this
            .http
            .get(`${environment.retrieveEmail}`+ email,httpOptions);
  }
  signUp(user:any){
    return this.http.post(environment.signUp,user);
  }
  passwordReset(user:any){
    return this.http.post(environment.resetPassword,user);
  }
  login(user:any){
    return this.http.post(`${environment.loginUser}`,user);
  }
  getProfile(user:any){
    const profileOptions = {
        headers: new HttpHeaders({
         "Authorization": "Bearer "+ user.token
        })
      }
    return this
            .http
            .get(`${environment.getProfile}`,profileOptions);
  }
  getTrips(phone:string){
    return this
            .http
            .get(`${environment.getTrips}`+ phone);
  }

}
