import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService, DataService } from '../services/index'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LocationStrategy } from '@angular/common';
import countryData from './country.json';
import { Base64 } from 'js-base64';
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  countries: any = countryData;
  paytotalfare:any;
  private sub: any;
  bookingdetails:any;
  returndetails:any;
  bookingdata:any={};
  bookingdataReturn:any={};
  passengers=[];
  fsname:any;
  lsname:any;
  isdno:any;
  phoneno:any;
  country:any;
  residence:any;
  returnpassengers=[];
  formValidation=[];
  isError:boolean=false;
  errorMessage:any;
  res:any;
  is_return:false;
  loading=false;
  paymentForm: UntypedFormGroup;
  submitted = false;
  formDataObj={}
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service:PaymentService,
    private dataService:DataService,
    private locationStrategy: LocationStrategy,
    private _fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    window.scroll(0,0);

    this.dataService.returnPaymentValueChange.subscribe(message =>{

      if(!message){
        message = localStorage.getItem('review-buupass')
        if(!message) {
         this.router.navigate(['/'])
       }
      }else{
        localStorage.setItem('review-buupass', message)
      }

      let encoded =JSON.parse(Base64.decode(message))
      this.bookingdetails = JSON.parse(Base64.decode(encoded.travel));
      if(encoded.returning){
        this.returndetails = JSON.parse(Base64.decode(encoded.return));
        this.paytotalfare=this.bookingdetails.totalfare + this.returndetails.totalfare ;
        this.returndetails.result.operator.booked_seat_session_id=this.bookingdetails.booking_session_id
      }else{
        this.paytotalfare=this.bookingdetails.totalfare;
        this.bookingdetails.result.operator.booked_seat_session_id=this.bookingdetails.booking_session_id
      }
      this.is_return=encoded.returning;
      this.bookingdetails.seatsSelected.forEach((seat) => {
        let fsname='fname'+seat.id;
        let lsname='lname'+seat.id;
        let isdno='idno'+seat.id;
        let phoneno = 'phoneno'+ seat.id;
        let country = 'country'+ seat.id;

        this.formDataObj[fsname]=['', [Validators.required,Validators.pattern('^[a-zA-Z\\s]+$')]];
        this.formDataObj[lsname]=['', [Validators.required,Validators.pattern('^[a-zA-Z\\s]+$')]];
        this.formDataObj[isdno]=['', [Validators.required, Validators.minLength(7)]];
        this.formDataObj[phoneno]=['', [Validators.required, Validators.minLength(10),Validators.pattern('^(?:256|\\+256|0)(7|1)[0-9]{8}$')]];
        this.formDataObj[country]=['', Validators.required];
      });
    });
    this.formDataObj['mobileNumber']=['',  [Validators.required, Validators.minLength(10),Validators.pattern('^(?:256|\\+256|0)(7|1)[0-9]{8}$')]];
    this.formDataObj['fullname']=['', Validators.required];
    this.formDataObj['email']=['', [Validators.required,Validators.email]];
    this.paymentForm =this._fb.group(this.formDataObj);

    console.log(this.returndetails);


  }
  get f() { return this.paymentForm.controls; }

  handleClick(event: Event) {

  }

  submitForm(){
    this.submitted=true;
    if (this.paymentForm.valid) {
    let value = this.paymentForm.value;
    this.isError=false;
    this.loading=true;
    this.passengers=[];
    this.bookingdetails.seatsSelected.forEach((seat) => {
      let passenger={};
      this.fsname='fname'+seat.id;
      this.lsname='lname'+seat.id;
      this.isdno='idno'+seat.id;
      this.phoneno = 'phoneno'+seat.id;
      this.country = 'country'+seat.id;

  let seatprice=(parseInt(this.bookingdetails.totalfare)/this.bookingdetails.seatsSelected.length)
  passenger={
      "full_name": value[this.fsname] + ' ' + value[this.lsname],
      "id_or_passport": value[this.isdno].toString(),
      "seat": {
        "id": seat.id,
        "fare": seatprice.toString(),
        "class": seat.space_type
      },
      "phone_number":  "0"+value[this.phoneno].substr(value[this.phoneno].length - 9),
      "residence":  "Kampala",
  }
  this.passengers.push(passenger);
});
if(this.is_return){
  this.returnpassengers=[];
  this.returndetails.seatsSelected.forEach((seat) => {
    let passenger={};
    let seatprice=(parseInt(this.returndetails.totalfare)/this.returndetails.seatsSelected.length)
    passenger={
      "full_name": value[this.fsname] + ' ' + value[this.lsname],
      "id_or_passport": value[this.isdno].toString(),
      "seat": {
        "id": seat.id,
        "fare": seatprice.toString(),
        "class": seat.space_type
      },
      "phone_number":  "0"+value[this.phoneno].substr(value[this.phoneno].length - 9),
      "residence":  "Kampala"
  }
this.returnpassengers.push(passenger);
});
  this.bookingdataReturn ={
      "booking_channel": "web",
      "transaction_reference": "",
      "referral_code": "",
      "residence":"Kampala",
      "promo_code": "",
      "selected_bus": {
        "from": this.returndetails.result.from,
        "to": this.returndetails.result.to,
        "departure_date": this.returndetails.result.departure_date,
        "bus_type_id": this.returndetails.result.bus_type_id,
        "operator": this.returndetails.result.operator,
        "is_express_travel": this.returndetails.result.is_express_travel,
        "number_of_booked_seats": this.returndetails.result.number_of_seats_booked,
        "id": this.returndetails.result.id,
        "make": this.returndetails.result.make,
        "model": this.returndetails.result.model,
        "route_id": this.returndetails.result.route_id,
        "route_schedule_id":  this.returndetails.result.route_schedule_id,
        "route_schedule_code":(this.bookingdetails.result.is_shuttle) ? '0' :  this.returndetails.result.route_schedule_code,
        "departure_time": this.returndetails.result.departure_time,
        "departure_timestamp": this.returndetails.result.departure_timestamp,
        "arrival_time": this.returndetails.result.arrival_time,
        "number_of_available_seats": this.returndetails.result.number_of_available_seats,
        "fare":''+this.returndetails.totalfare + '.00',
        "boarding_points": this.returndetails.result.boarding_points,
        "dropoff_points":this.returndetails.result.dropoff_points,
        "seats":this.returndetails.result.seats,
        "seat_types": this.returndetails.result.seats_types,
        "number_of_window_seats": this.returndetails.result.number_of_window_seats,
        "last_booking": this.returndetails.result.last_booking,
        "amenities":this.returndetails.result.amenities,
        "origin_city_id": this.returndetails.result.origin_city_id,
        "destination_city_id": this.returndetails.result.destination_city_id,
        "bus_capacity": this.returndetails.result.bus_capacity,
        "trip_id":this.returndetails.result.trip_id,
      },
      "payee": {
        "phone_number": '256' + (value.mobileNumber.length > 9 ? value.mobileNumber.slice(-9) : value.mobileNumber), 
  		    "name": value.fullname,
  		     "email": value.email
      },
      "boarding_point": this.returndetails.pickup,
      "date_of_travel": this.returndetails.result.departure_date,
      "departure_time": this.returndetails.result.departure_time,
      "arrival_time": this.returndetails.result.arrival_time,
      "route_schedule_id": this.returndetails.result.route_schedule_id,
      "operator": this.returndetails.result.operator,
      "origin_city_name":this.returndetails.result.from,
      "destination_city_name": this.returndetails.result.to,
      "origin_city_id":this.returndetails.result.origin_city_id,
      "destination_city_id": this.returndetails.result.destination_city_id,
      "passengers":  this.returnpassengers,
      "seatsSelected":this.returndetails.seatsSelected,
      "total_fare": this.returndetails.total_fare,
      "children": []
    }

    this.bookingdata ={
      "booking_channel": "web",
      "transaction_reference": "",
      "referral_code": "",
      "residence":"Kampala",
      "promo_code": "",
      "selected_bus": {
        "from": this.bookingdetails.result.from,
        "to": this.bookingdetails.result.to,
        "departure_date": this.bookingdetails.result.departure_date,
        "bus_type_id": this.bookingdetails.result.bus_type_id,
        "operator": this.bookingdetails.result.operator,
        "is_express_travel": this.bookingdetails.result.is_express_travel,
        "number_of_booked_seats": this.bookingdetails.result.number_of_seats_booked,
        "id": this.bookingdetails.result.id,
        "make": this.bookingdetails.result.make,
        "model": this.bookingdetails.result.model,
        "route_id": this.bookingdetails.result.route_id,
        "route_schedule_id":  this.bookingdetails.result.route_schedule_id,
        "route_schedule_code":(this.bookingdetails.result.is_shuttle) ? '0' :  this.bookingdetails.result.route_schedule_code,
        "departure_time": this.bookingdetails.result.is_shuttle ? '00:00:00' : this.bookingdetails.result.departure_time,
        "departure_timestamp": this.bookingdetails.result.departure_timestamp,
        "arrival_time": this.bookingdetails.result.is_shuttle ? '00:00:00' : this.bookingdetails.result.arrival_time,
        "number_of_available_seats": this.bookingdetails.result.number_of_available_seats,
        "fare": ''+this.bookingdetails.totalfare + '.00',
        "boarding_points": this.bookingdetails.result.boarding_points,
        "dropoff_points":this.bookingdetails.result.dropoff_points,
        "seats":this.bookingdetails.result.seats,
        "seat_types": this.bookingdetails.result.seats_types,
        "number_of_window_seats": this.bookingdetails.result.number_of_window_seats,
        "last_booking": this.bookingdetails.result.last_booking,
        "amenities":this.bookingdetails.result.amenities,
        "origin_city_id":this.bookingdetails.result.origin_city_id,
        "destination_city_id": this.bookingdetails.result.destination_city_id,
        "bus_capacity": this.bookingdetails.result.bus_capacity,
        "trip_id":this.bookingdetails.result.trip_id,
      },
      "payee": {
        "phone_number": '256' + (value.mobileNumber.length > 9 ? value.mobileNumber.slice(-9) : value.mobileNumber),
  		    "name": value.fullname,
  		     "email": value.email
      },
      "boarding_point": this.bookingdetails.pickup,
      "date_of_travel": this.bookingdetails.result.departure_date,
      "departure_time": this.bookingdetails.result.departure_time,
      "arrival_time": this.bookingdetails.result.arrival_time,
      "route_schedule_id":'' + this.bookingdetails.result.route_schedule_id,
      "operator": this.bookingdetails.result.operator,
      "origin_city_name":this.bookingdetails.result.from,
      "destination_city_name": this.bookingdetails.result.to,
      "origin_city_id":this.bookingdetails.result.origin_city_id,
      "destination_city_id": this.bookingdetails.result.destination_city_id,
      "passengers":  this.passengers,
      "children": []
    }

    let bookingdata=Base64.encode(JSON.stringify(this.bookingdata));
    let bookingdataReturn=Base64.encode(JSON.stringify(this.bookingdataReturn));
    let bookingdetails=Base64.encode(JSON.stringify(this.bookingdetails));
    let webformdata={
      'bookingdata' : bookingdata,
      'bookingdetails' : bookingdetails,
      'is_return':this.is_return,
      'returndata':  bookingdataReturn
    }
    console.log(this.bookingdataReturn);
    let pay=Base64.encode(JSON.stringify(webformdata));
    let paymessage=Base64.encode("Sorry!");
    this.dataService.changeMessage(pay);
    this.router.navigate(['/review-and-pay', paymessage])

}
  this.bookingdata ={
    "booking_channel": "web",
    "transaction_reference": "",
    "referral_code": "",
    "residence":"Kampala",
    "promo_code": "",
    "selected_bus": {
      "from": this.bookingdetails.result.from,
      "to": this.bookingdetails.result.to,
      "departure_date": this.bookingdetails.result.departure_date,
      "bus_type_id": this.bookingdetails.result.bus_type_id,
      "operator": this.bookingdetails.result.operator,
      "is_express_travel": ''+this.bookingdetails.result.is_express_travel,
      "number_of_booked_seats": this.bookingdetails.result.number_of_seats_booked,
      "id": ''+this.bookingdetails.result.id,
      "make": this.bookingdetails.result.make,
      "model": this.bookingdetails.result.model,
      "route_id": this.bookingdetails.result.route_id,
      "route_schedule_id": this.bookingdetails.result.route_schedule_id ,
      "route_schedule_code": (this.bookingdetails.result.is_shuttle) ? '0' : this.bookingdetails.result.route_schedule_code,
      "departure_time": (this.bookingdetails.result.is_shuttle) ? '00:00:00' : this.bookingdetails.result.departure_time,
      "departure_timestamp": this.bookingdetails.result.departure_timestamp,
      "arrival_time":(this.bookingdetails.result.is_shuttle) ? '00:00:00' : this.bookingdetails.result.arrival_time,
      "number_of_available_seats": this.bookingdetails.result.number_of_available_seats,
      "fare": this.bookingdetails.totalfare ,
      "boarding_points": this.bookingdetails.result.boarding_points,
      "dropoff_points":this.bookingdetails.result.dropoff_points,
      "seats":this.bookingdetails.result.seats,
      "seat_types": this.bookingdetails.result.seats_types,
      "number_of_window_seats": this.bookingdetails.result.number_of_window_seats,
      "last_booking": this.bookingdetails.result.last_booking,
      "amenities":this.bookingdetails.result.amenities,
      "origin_city_id":this.bookingdetails.result.origin_city_id,
      "destination_city_id": this.bookingdetails.result.destination_city_id,
      "bus_capacity": this.bookingdetails.result.bus_capacity,
      "trip_id":this.bookingdetails.result.trip_id,
    },
    "payee": {
      "phone_number": '256' + (value.mobileNumber.length > 9 ? value.mobileNumber.slice(-9) : value.mobileNumber),
		    "name": value.fullname,
		     "email": value.email
    },
    "boarding_point": this.bookingdetails.pickup,
    "date_of_travel": this.bookingdetails.result.departure_date,
    "departure_time": (this.bookingdetails.result.is_shuttle) ? '00:00:00' : this.bookingdetails.result.departure_time,
    "arrival_time": (this.bookingdetails.result.is_shuttle) ? '00:00:00' : this.bookingdetails.result.arrival_time,
    "route_schedule_id": this.bookingdetails.result.route_schedule_id,
    "operator": this.bookingdetails.result.operator,
    "origin_city_name":this.bookingdetails.result.from,
    "destination_city_name": this.bookingdetails.result.to,
    "origin_city_id":this.bookingdetails.result.origin_city_id,
    "destination_city_id": this.bookingdetails.result.destination_city_id,
    "passengers":  this.passengers,
    "children": []
  }

  let bookingdata=Base64.encode(JSON.stringify(this.bookingdata));
  let bookingdataReturn=Base64.encode(JSON.stringify(this.bookingdataReturn));
  let bookingdetails=Base64.encode(JSON.stringify(this.bookingdetails));

  let webformdata={
    'bookingdata' : bookingdata,
    'bookingdetails' : bookingdetails,
    'is_return':this.is_return,
    'returndata':  bookingdataReturn
  }


  let pay=Base64.encode(JSON.stringify(webformdata));
  let paymessage=Base64.encode("Sorry!");
  this.dataService.changeMessage(pay);
  this.router.navigate(['/review-and-pay', paymessage])


  }
}

}
