
  <div class="row">
      <div (click)="toggle($event)" class="col-md-12 {{isFocused ? 'focus':''}}" [ngClass]="{'is-open':isOpen,'input-opened':isOpen}">
        <div class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white  theme-search-area-section-mr">
          <div class="theme-search-area-section-inner">
            <i class="theme-search-area-section-icon fa fa-map-marker-alt "></i>
            <input (ngModelChange)="checkIfOpen($event)" class="theme-search-area-section-input " type="text" [ngbTypeahead]="search" [(ngModel)]="location" type="text"  placeholder="{{placeholder}}" data-provide="typeahead">
          </div>
        </div>
      </div>
  </div>

<div class="row" [@dialog]>
    <div class="col-md-12" >
      <ul class="dropdown-options" [ngClass]="{'input-opened-options':isOpen}" *ngIf="!options">
        <span>Loading</span>
      </ul>
      <ul class="dropdown-options" [ngClass]="{'input-opened-options':isOpen}" *ngIf="options">
        <ng-container>
          <li id="lif{{i}}"
            *ngFor="let option of options.slice(0, 8); let i = index"
            (click)="optionSelect(option, i, $event)"
          >
          <i class="fa fa-map-marker-alt _mr-10" aria-hidden="true"></i> {{option}}
          </li>
        </ng-container>

      </ul>
    </div>
</div>


<!-- <div class="dropdown-wrapper {{className}} {{isFocused ? 'focus':''}}" [ngClass]="{'is-open':isOpen, 'disabled':isReadOnly}"  (contextmenu)="$event.stopPropagation();">

    <div class="box" (click)="toggle($event)" class="theme-search-area-section first theme-search-area-section-fade-white theme-search-area-section-no-border theme-search-area-section-mr theme-search-area-section-sm theme-search-area-section-curved">
      <div  class="dropdown-selected theme-search-area-section-inner">
        <i class="theme-search-area-section-icon lin lin-location-pin"></i>
        <input (ngModelChange)="checkIfOpen($event)" class="theme-search-area-section-input " [ngbTypeahead]="search" [(ngModel)]="location" type="text"  placeholder="{{placeholder}}" data-provide="typeahead">
      </div>
    </div>


      <ul class="dropdown-options" *ngIf="options">
        <span>Top</span>
        <ng-container>
          <li id="lif{{i}}"
            *ngFor="let option of options.slice(0, 5); let i = index"
            [class.active]="selected === i"
            (click)="optionSelect(option, i, $event)"
            l10nTranslate
          >
          <i class="fa fa-map-marker" aria-hidden="true"></i> {{option}}
          </li>
        </ng-container>

      </ul>
    </div> -->
