import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService, DataService } from '../services/index';
import { LocationStrategy } from '@angular/common';
import { Error } from '../models/index'
import { Base64 } from 'js-base64';
@Component({
  selector: 'app-review-pay',
  templateUrl: './review-pay.component.html',
  styleUrls: ['./review-pay.component.css']
})
export class ReviewPayComponent implements OnInit {

  paytotalfare:any;
  bookingdata:any;
  bookingdetails:any;
  return_trip:any;
  is_return=false;
  loading=false;
    isCard=false;
    isMpesa=false;
    isVooma=false;
  public isCollapsed = true;
  public isCollapsedVooma = true;
  public isCollapsedCard = true;
  paymentType:any;
  is_selected =false
  isError:boolean=false;
  errorMessage:any;
  res:any;
  api_error:Error;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service:PaymentService,
    private dataService:DataService,
    private locationStrategy: LocationStrategy,
  ) { }

  ngOnInit(): void {
    this.isCollapsedCard=false
    this.isMpesa=true;
    window.scroll(0,0);
    this.dataService.currentMessage.subscribe(message =>{
      if(!message){
        message = localStorage.getItem('review-buupass')
      }else{
        localStorage.setItem('review-buupass', message)
      }
      let encoded =JSON.parse(Base64.decode(message))
      this.bookingdata =  JSON.parse(Base64.decode(encoded.bookingdata));
      this.bookingdetails = JSON.parse(Base64.decode(encoded.bookingdetails));
      console.log(this.bookingdetails.search_details)
      this.is_return =  encoded.is_return;

      if(encoded.is_return){
        this.return_trip =  JSON.parse(Base64.decode(encoded.returndata));
        this.paytotalfare=this.bookingdetails.total_fare + this.return_trip.total_fare;
      }else{
        this.paytotalfare=this.bookingdetails.total_fare;
      }
    });

   

  }
  ngAfterViewInit(): void {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  tConvert(time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }
  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }

onPaymentMethodChange(paymentMethod: string) {
  this.is_selected =true
  this.paymentType =paymentMethod
  // this.selectedPaymentMethod = paymentMethod;
  // this.disableInputsExcept(paymentMethod + 'Number');

  // if(paymentMethod ==="mtn" || paymentMethod ==="airtel"){
  //   this.paymentForm.get('countryCode').setValue('256')
  // }else{
  //   this.paymentForm.get('countryCode').setValue('254')

  // }
  
}

submitBooking(){


if(this.paymentType){
  let bookingData:any;
  if(this.is_return){
    this.bookingdata.payment_channel=this.paymentType
    this.return_trip.payment_channel=this.paymentType
    bookingData={
      "initial_trip":this.bookingdata,
      "return_trip":this.return_trip
    }
  }else{
    bookingData=this.bookingdata;
    this.bookingdata.payment_channel=this.paymentType
  }

  this.loading=true;
  this.service.submitBooking(bookingData,this.bookingdetails.result.operator).subscribe(
    data  => {
      this.res=data.data;
      if(data.status == true){
        let others={
          'data':this.res,
          'payment_type':this.paymentType,
          'trip_id':this.bookingdetails.result.trip_id,
          'fullname':this.bookingdata.payee.name,
          'email':this.bookingdata.payee.email,
          'phoneno':this.bookingdata.payee.phone_number,
          'depature_date':this.bookingdetails.result.departure_date,
          'departure_time':this.bookingdetails.result.departure_time,
          'arrival_time':this.bookingdetails.result.arrival_time,
          'from':this.bookingdetails.result.from,
          'to':this.bookingdetails.result.to,
          "passengers":  this.bookingdata.passengers,
          "pickup": this.bookingdetails.search_details.pickup,
          "dropoff": this.bookingdetails.search_details.dropoff,
          "operator": this.bookingdetails.result.operator,
          "is_shuttle":this.bookingdetails.result.is_shuttle

        };
        let f=Base64.encode(JSON.stringify(others))
        this.router.navigate(['/confirm-payments', f])
      }else{
        this.res.
        this.loading=false;
        this.isError=true;
        this.errorMessage=this.res.message;
      }

    },
    error  => {

      this.loading=false;
      this.isError=true;
      if(error.status==500){
        this.errorMessage="Sorry there has been a technical problem.Please try again ";
      }else{
        this.api_error=new Error().getTheMessage(error.error.errors)
        this.errorMessage=this.api_error;
      }

    }

    );
}
}




}
