import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators'
import  { environment } from '../../environments/environment'
const httpOptions = {
  headers: new HttpHeaders({
   'Content-Type':  'application/json',
   'Authorization':  'Api-Key '+ environment.api_key
  })
};

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http:HttpClient
  ) { }

  submitBooking(booking:any,operator:any){
    return this.http.post<any>(environment.bookingApi,booking,httpOptions);
  }

  submitBookingEasy(reference:any,operator:any,booking:any){
    return this.http.post(environment.easyUrl +'?transaction_reference='+reference+'&operator_alias='+operator.alias,booking);
  }


  submitBookingReturn(booking:any){
    return this.http.post(environment.bookingApiV2,booking);
  }

}
