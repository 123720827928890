<nav
  class="navbar bHTikb sticky-top navbar-default navbar-fixed-top buupass-box navbar-inverse navbar-theme navbar-theme-abs navbar-theme-transparent navbar-theme-border"
  id="main-nav">
  <div class="nav-overlay"></div>

  <div class="navbar-inner nav">
    <div class="navbar-header">
      <button class="navbar-toggle collapsed" data-target="#navbar-main" data-toggle="collapse" type="button"
        area-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>


      <a  class="navbar-brand-logo _mt-20" href="index.html">
        <img  src="assets/img/company1/logo-name.png" alt="buupass.com" width="70" alt="Image Alternative text" title="Image Title"/>
      </a>
    </div>
    <div class="collapse navbar-collapse" id="navbar-main">

      <ul class="nav navbar-nav pd-nav">
        <li class="active">
          <a href="index.html" class="headerAccount" role="button">
            Buses
          </a>
        </li>
        <li>
          <a href="https://metickets.krc.co.ke" class="headerAccount" role="button">
            Trains
          </a>
        </li>
        <!-- <li>
          <a href="https://flights.buupass.com" class="headerAccount" role="button">
            Flights
          </a>
        </li> -->


        <!-- <li >
                <a href="https://hire.buupass.com/"  role="button" >
                  CAR HIRE
                </a>
            </li> -->


      </ul>

      <ul class="nav navbar-nav navbar-right">
        <li>
          <a href="index.html" class="headerAccount" role="button">
            Help
          </a>
        </li>
        <li>
          <a data-toggle="modal" data-target="#manageBooking" class="headerAccount" role="button">
            Manage my account
          </a>
        </li>
        <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal">Open Modal</button>
        <button id="openSignupButton" [hidden]="true" data-toggle="modal" data-target="#myCreateModal">Open Modal</button>

        <!-- <li>
          <a data-toggle="modal" data-target="#myModal" class="headerAccount" role="button">
            Sign in
          </a>

        </li>
        <li>
          <a  data-toggle="modal" data-target="#myCreateModal"  class="headerAccount" role="button">
            Create an account
          </a>
        </li> -->
        <li *ngIf="is_loggedin" class="navbar-nav-item-user dropdown">
              <a class="dropdown-toggle headerAccount" href="account.html" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd" clip-rule="evenodd">
                  <path
                    d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm8.127 19.41c-.282-.401-.772-.654-1.624-.85-3.848-.906-4.097-1.501-4.352-2.059-.259-.565-.19-1.23.205-1.977 1.726-3.257 2.09-6.024 1.027-7.79-.674-1.119-1.875-1.734-3.383-1.734-1.521 0-2.732.626-3.409 1.763-1.066 1.789-.693 4.544 1.049 7.757.402.742.476 1.406.22 1.974-.265.586-.611 1.19-4.365 2.066-.852.196-1.342.449-1.623.848 2.012 2.207 4.91 3.592 8.128 3.592s6.115-1.385 8.127-3.59zm.65-.782c1.395-1.844 2.223-4.14 2.223-6.628 0-6.071-4.929-11-11-11s-11 4.929-11 11c0 2.487.827 4.783 2.222 6.626.409-.452 1.049-.81 2.049-1.041 2.025-.462 3.376-.836 3.678-1.502.122-.272.061-.628-.188-1.087-1.917-3.535-2.282-6.641-1.03-8.745.853-1.431 2.408-2.251 4.269-2.251 1.845 0 3.391.808 4.24 2.218 1.251 2.079.896 5.195-1 8.774-.245.463-.304.821-.179 1.094.305.668 1.644 1.038 3.667 1.499 1 .23 1.64.59 2.049 1.043z" />
                </svg>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a href="#">Account</a>
                </li>
                <li>
                  <a href="#">Bookings</a>
                </li>
                <li>
                  <a href="#">Alerts</a>
                </li>
                <li>
                  <a href="#">Logout</a>
                </li>

              </ul>
            </li>
      </ul>
    </div>
  </div>

</nav>

<!-- Modal -->
<div id="manageBooking" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button id="closeStart" type="button" class="close" data-dismiss="modal">&times;</button>
        <br>

        <div class="modal-body">
          <h4 class="theme-payment-page-signin-title review-header _ml-10">Continue to your account</h4>

          <div class="facebook-login__disclaimer  _mb-20 _ml-10">

            <p class="paragraphRegular">Please enter your email to Sign in or register .</p>
          </div>

          <!-- <div class="or__splitter"><span>or</span></div> -->
          <form [formGroup]="startForm" id="ngFormBegin" (ngSubmit)="submitStart()" >
            <div class="form-row  _mb-20 ">

                <div class="col-sm-8 my-1">
                  <label class="sr-only" for="inlineFormInputEmail">Email</label>
                  <input type="text"
                  class="form-control"
                  id="inlineFormInputEmail"
                  placeholder="e.g your@email.com"
                  name="email"
                  formControlName="email"
                  >

                </div>
                <div class="col-auto my-1">
                  <button   form="ngFormBegin" class="theme-search-area-submit theme-search-area-submit-curved theme-search-area-submit-glow">
                    CONTINUE
                  </button>
                </div>


            </div>
            </form>
            <small class=" _ml-10 invalid-select" *ngIf="startForm.get('email').touched && startForm.get('email').hasError('required')">This field is required!</small>
            <small class=" _ml-10 invalid-select" *ngIf="startForm.get('email').touched && startForm.get('email').hasError('email')">Should be a valid email address</small>

<p class="_ml-10">Terms & conditions</p>

        </div>

      </div>

    </div>
  </div>
  </div>

<!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <br>
        <div class="modal-body">
          <h4 class="theme-payment-page-signin-title review-header  _ml-10">Continue to your account</h4>
          <div class="facebook-login__disclaimer _ml-10">
            <p class="paragraphRegular">Enter your password.</p>
          </div>
          <div class="row">
            <div class="col-md-8 _ml-10">
              <div class="row" data-gutter="10">
                <div class="col-md-12 mfb-form__row">
                  <div
                    class="theme-search-area-section first theme-search-area-section-border-light theme-search-area-section-curved">
                    <div class="theme-search-area-section-inner">
                      <input class="theme-search-area-section-input typeahead" type="password" placeholder=""
                        data-provide="typeahead">
                    </div>
                  </div>
                </div>
                <div class="row mfb-form__row user-login-form__row__pass-reset">
                  <div class="col-xs-12 user-login-form__pass-reset"><a class="user-login-form__link" href="#">Forgot
                      password?</a></div>
                </div>

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2 col-md-offset-8">
              <button class="theme-search-area-submit theme-search-area-submit-curved theme-search-area-submit-glow">
                SUBMIT
              </button>
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>
  </div>


  <!-- Modal -->
  <div id="myCreateModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <br>
          <h4 class="theme-payment-page-signin-title review-header _mb-10 text-center">Sign up</h4>

          <div class="modal-body">
            <form class="makeFlex" id="ngFormRegister" [formGroup]="signupForm" (ngSubmit)="submitSignUp()">
            <div class="trvlrs-dtl _ml-30">
                <div class="c-accordion-item  expanded">
                    <div class="accordion-body" style="display: block;">
                        <div class="general-error"></div>
                        <div class="trvlr-forms-cntnr u-ib">
                            <div class="trvlr-form-cntnr">
                                <div class="trvlr-form">
                                    <div >
                                        <div class="form-hdr"><i class="text-light fa fa-bookmark"></i> Account Details</div>
                                        <div class="row" data-gutter="30">
                                            <div class="col-md-6">
                                            <div class="theme-search-area-section first theme-search-area-section-line">
                                                <label for="fname" class="theme-search-area-section-label">Firstname</label>
                                                <div class="theme-search-area-section-inner">
                                                <input class="theme-search-area-section-input typeahead"
                                                        type="text"
                                                        name="fname"
                                                        id="fname"
                                                        placeholder=""
                                                        tabindex="0"
                                                        formControlName="fname"
                                                  >
                                                </div>
                                                <small class="invalid-select" *ngIf="signupForm.get('fname').touched && signupForm.get('fname').hasError('pattern')">Your firstname should not contain numbers or spaces!</small>
                                                <small class="invalid-select" *ngIf="signupForm.get('fname').touched && signupForm.get('fname').hasError('required')">This field is required!</small>

                                            </div>
                                            </div>
                                            <div class="col-md-6">
                                                    <div class="theme-search-area-section first theme-search-area-section-line">
                                                    <label  for="lname" class="theme-search-area-section-label">Lastname</label>
                                                    <div class="theme-search-area-section-inner">
                                                        <input class="theme-search-area-section-input typeahead"
                                                        type="text"
                                                        name="lname"
                                                        id="lname"
                                                        tabindex="0"
                                                        formControlName="lname"
                                                        >
                                                    </div>
                                                    <small class="invalid-select" *ngIf="signupForm.get('lname').touched && signupForm.get('lname').hasError('pattern')">Your lastname should not contain numbers or spaces</small>
                                                    <small class="invalid-select" *ngIf="signupForm.get('lname').touched && signupForm.get('lname').hasError('required')">This field is required!</small>

                                                    </div>
                                            </div>



                                        </div>
                                        <div class="row" data-gutter="30">
                                                <div class="col-md-6 ">
                                                  <div class="theme-search-area-section first theme-search-area-section-line">
                                                      <label for="phoneno" class="theme-search-area-section-label">Phone Number</label>
                                                      <div class="theme-search-area-section-inner">
                                                      <input class="theme-search-area-section-input typeahead"
                                                              type="text"
                                                              name="phoneno"
                                                              id="phoneno"
                                                              placeholder=""
                                                              tabindex="0"
                                                              formControlName="phoneno"
                                                        >
                                                      </div>
                                                      <small class="invalid-select" *ngIf="signupForm.get('phoneno').touched && signupForm.get('phoneno').hasError('minlength')">should not be less or greater than 10</small>
                                                      <small class="invalid-select" *ngIf="signupForm.get('phoneno').touched && signupForm.get('phoneno').hasError('required')">this field is required!</small>
                                                      <small class="invalid-select" *ngIf="signupForm.get('phoneno').touched && signupForm.get('phoneno').hasError('pattern')">Your phone number should be valid!</small>


                                                  </div>
                                                </div>
                                                <div class="col-md-6">
                                                        <div class="theme-search-area-section first theme-search-area-section-line">
                                                        <label for="idno" class="theme-search-area-section-label">ID/Passport</label>
                                                        <div class="theme-search-area-section-inner">
                                                            <input class="theme-search-area-section-input typeahead"
                                                             type="text"
                                                             name="idno"
                                                            id="idno"
                                                            placeholder=""
                                                            type="text"
                                                            tabindex="0"
                                                            formControlName="idno"
                                                             >
                                                        </div>
                                                        <small class="invalid-select" *ngIf="signupForm.get('idno').touched && signupForm.get('idno').hasError('minlength')">Your Id/passport should not be less or greater than 20</small>
                                                        <small class="invalid-select" *ngIf="signupForm.get('idno').touched && signupForm.get('idno').hasError('required')">This field is required!</small>

                                                        </div>
                                                </div>

                                        </div>
                                        <div class="row" data-gutter="30">
                                          <div class="col-md-12">
                                                  <div class="theme-search-area-section first theme-search-area-section-line">
                                                      <label for="email" class="theme-search-area-section-label">Email</label>
                                                      <div class="theme-search-area-section-inner">
                                                      <input class="theme-search-area-section-input typeahead"
                                                              type="text"
                                                              placeholder="Email"
                                                              name="email"
                                                              id="email"
                                                              placeholder=""
                                                              tabindex="0"
                                                              formControlName="email"
                                                        >
                                                      </div>
                                                      <small class="invalid-select" *ngIf="signupForm.get('email').touched && signupForm.get('email').hasError('required')">This field is required!</small>
                                                      <small class="invalid-select" *ngIf="signupForm.get('email').touched && signupForm.get('email').hasError('email')"> Should be a email!</small>

                                                  </div>
                                          </div>
                                        </div>
                                        <div class="row" data-gutter="30">
                                          <div class="col-md-12">
                                                  <div class="theme-search-area-section first theme-search-area-section-line">
                                                      <label for="email" class="theme-search-area-section-label">Password</label>
                                                      <div class="theme-search-area-section-inner">
                                                      <input class="theme-search-area-section-input typeahead"
                                                              type="password"
                                                              placeholder="Your pasword"
                                                              name="password"
                                                              id="password"
                                                              placeholder=""
                                                              tabindex="0"
                                                              formControlName="password"
                                                        >
                                                      </div>
                                                      <small class="invalid-select" *ngIf="signupForm.get('password').touched && signupForm.get('password').hasError('required')">This field is required!</small>

                                                  </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="trvlr-list-cntnr u-ib">
                            <div class="c-trvlr-list-wrpr">
                                <div class="c-trvlr-list-cntnr u-cstm-scroll" style="max-height: 250px;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="row _mt-20 ">
                    <div class="col-md-3 col-md-offset-7">
                      <button type="submit"  class=" _ml-30 theme-search-area-submit btn-block theme-search-area-submit-curved  theme-search-area-submit-glow">SUBMIT</button>
                    </div>
                  </div>
              </div>
            </form>

<p class="text-center _mt-10">By creating an account you agree to our
Terms of Use and Privacy Policy</p>

          </div>

        </div>

      </div>
    </div>
    </div>
