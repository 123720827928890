import { Component, AfterViewInit, Output, EventEmitter, Input, ViewChild  } from '@angular/core';
import { City } from 'src/app/models/index';
import { TownsService } from 'src/app/services/towns.service';
import { Router } from '@angular/router';
import { DropdownStatusService } from 'src/app/services/dropdown-status.service';

let topCities =[
  {"name": "Kampala", 
  "alias": "Kmpl"
  },
  {"name": "Adjumani", 
   "alias": "Adjmn"
  },
  {"name": "Arua", 
  "alias": "Ara"
  },
  {"name": "Moyo", 
    "alias": "My"
  }]
@Component({
  selector: 'app-mobile-to',
  templateUrl: './mobile-to.component.html',
  styleUrls: ['./mobile-to.component.css']
})
export class MobileToComponent implements AfterViewInit {
  @Input() searchModel;
  @Output() searchModelChange: EventEmitter<any> = new EventEmitter();
  cities:any;
  selectedCity: City;
    show:boolean = false;
    @ViewChild("toInputFocus") toInputFocus;
  constructor(
    private router: Router,
    private townService: TownsService,
    private destinationService:DropdownStatusService,
  ) {
      this.townService.currentValue.subscribe(message => {
        this.cities =[...topCities, ...message];
        this.cities = this.cities.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.name === value.name && t.alias === value.alias
        ))
      )
      });
      if(this.cities.length == 0){
        this.cities=JSON.parse(atob(localStorage.getItem('cities')));
        console.log(this.cities);
      }

      console.log(this.cities.length);
   }
   ngAfterViewInit(): void {
     this.showSearch();
     //this.toInputFocus.nativeElement.focus();
   }
   showSearch(){
     this.show = !this.show;
     setTimeout(()=>{ // this will make the execution after the above boolean has changed
       this.toInputFocus.nativeElement.focus();
     },0);
   }
   cancel() {
     this.router.navigateByUrl('/home');
   }
   onSelect(city: City): void {
     this.selectedCity = city;
    this.destinationService.setDropOff(city.name);
     this.router.navigateByUrl('/home');
   }
   updateSearchModel(value) {
     this.searchModel = value;
     this.searchModelChange.emit(this.searchModel);
   }
}
