import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownStatusService } from '../../services/dropdown-status.service';
import { FromStatusService } from '../../services/from-status.service';
import { ReturnServiceService } from '../../services/return-service.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { formatDate  } from '@angular/common';
import { DataService } from '../../services/data.service';
@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css']
})
export class SearchFormComponent implements OnInit,AfterViewInit {
  pickup='';
  dropoff='';
  reactiveForm: UntypedFormGroup;
  mobiletraveldate='';
  mobilereturndate='';
  returning=false;
  formdata={};
  submitted = false;
  isOpen=false;
  bsInlineValue =formatDate(new Date(),"yyyy-MM-dd","en-KE");
  constructor(
    private router: Router,
    private destinationService: DropdownStatusService,
    private fromService: FromStatusService,
    private returnservice: ReturnServiceService,
    private _fb: UntypedFormBuilder,
    private dataService : DataService,
  ) {
    this.reactiveForm = _fb.group({
      pickup:[null, Validators.required],
      dropoff:[null, Validators.required],
      mobiletraveldate:[this.bsInlineValue, Validators.required],
      mobilereturndate:[this.bsInlineValue]
    });
      this.destinationService.dropoffDateSource.subscribe(dvalue => {
        this.reactiveForm.controls['dropoff'].setValue(dvalue);
        this.dropoff=dvalue;
      });
      this.fromService.valueMobileChange.subscribe(dvalue => {
        this.reactiveForm.controls['pickup'].setValue(dvalue);
        this.pickup=dvalue;
      });
      this.dataService.travelValueMobile.subscribe(tvalue => {
        this.mobiletraveldate=tvalue;
        this.reactiveForm.controls['mobiletraveldate'].setValue(tvalue);
      });
      this.dataService.returndateMobileChange.subscribe(rvalue => {
          this.mobilereturndate=rvalue;
          this.reactiveForm.controls['mobilereturndate'].setValue(rvalue);
      });
      this.returnservice.currentValue.subscribe(rvalue => {
        this.returning=rvalue;
      });
   }
    ngOnInit(): void {
    }
    ngAfterViewInit() {
      this.returnservice.currentValue.subscribe(rvalue => {
        this.returning=rvalue;
      });
    }
  destinationsClicked(){
    this.router.navigate(['/mobile-to'])
  }
  depaturesClicked(){
    this.router.navigate(['/mobile-from'])
  }
  depatureDateClicked(){
    this.router.navigate(['/depature-date'])
  }
  returnDateClicked(){
    this.router.navigate(['/return-date'])
  }
  isOneWayClicked(){
    this.returning =false;
    this.returnservice.changeValue(false);
  }
  isReturnTripClicked(){
    this.returning =true;
    this.returnservice.changeValue(true);
  }
  submitForm() {
    this.submitted = true;

    if (this.reactiveForm.invalid) {
        return;
    }

    let value = this.reactiveForm.value;

    // Ensure traveldate and returndate are not undefined
    let traveldate = value.traveldate ? formatDate(value.traveldate, "yyyy-MM-dd", "en-KE") : formatDate(new Date(), "yyyy-MM-dd", "en-KE");
    let returndate = value.returndate ? formatDate(value.returndate, "yyyy-MM-dd", "en-KE") : null; // Allow null if one-way trip

    value.traveldate = traveldate;
    if (this.returning) {
        value.returndate = returndate;
    }

    this.formdata = {
        'pickup': value.pickup,
        'dropoff': value.dropoff,
        'pickupname': value.pickup,
        'dropoffname': value.dropoff,
        'traveldate': traveldate,
        'returndate': returndate,
        'returning': this.returning
    };

    const route = `${value.pickup}-to-${value.dropoff}`;

    let queryParams = {
        fromCityName: value.pickup,
        toCityName: value.dropoff,
        onward: traveldate,
        ...(this.returning && returndate && { return: returndate }) // Only include 'return' if returning is true and returndate is not null
    };

    this.router.navigate([`/booking/${route}`], { queryParams });
}


}
