import { Component, Output, EventEmitter, Input, ViewChild, AfterViewInit  } from '@angular/core';
import { City } from 'src/app/models/index';
import { TownsService } from 'src/app/services/towns.service';
import { Router } from '@angular/router';
import { FromStatusService } from 'src/app/services/from-status.service';

let topCities =[
  {"name": "Kampala", 
  "alias": "Kmpl"
  },
  {"name": "Adjumani", 
   "alias": "Adjmn"
  },
  {"name": "Arua", 
  "alias": "Ara"
  },
  {"name": "Moyo", 
    "alias": "My"
  }]
@Component({
  selector: 'app-mobile-from',
  templateUrl: './mobile-from.component.html',
  styleUrls: ['./mobile-from.component.css']
})
export class MobileFromComponent implements AfterViewInit {
  @Input() searchModel;
  @Output() searchModelChange: EventEmitter<any> = new EventEmitter();
  cities:any;
  selectedCity: City;
  show:boolean = false;
  @ViewChild("fromInputFocus") fromInputFocus;
  constructor(
    private router: Router,
    private townService: TownsService,
    private fromService:FromStatusService,
  ) {
      this.townService.currentValue.subscribe(message => {
        this.cities =[...topCities, ...message];

        this.cities = this.cities.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.name === value.name && t.alias === value.alias
        ))
      )
       
      });
      if(this.cities.length == 0){
        this.cities=JSON.parse(atob(localStorage.getItem('cities')));
        
      }


   }
  ngAfterViewInit(): void {
    this.showSearch();
    //this.fromInputFocus.nativeElement.focus();
  }
  showSearch(){
    this.show = !this.show;
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.fromInputFocus.nativeElement.focus();
    },0);
  }
  cancel() {
    this.router.navigateByUrl('/home');
  }
  onSelect(city: City): void {
    this.selectedCity = city;
    this.fromService.changeSelectedMobileValue(city.name);
    this.router.navigateByUrl('/home');
  }
  updateSearchModel(value) {
    this.searchModel = value;
    this.searchModelChange.emit(this.searchModel);
  }

}
