import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../services/index'
import { Base64 } from 'js-base64';
@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  sub:any;
  ticketDetails:any;
  rawTicketDetails:any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.dataService.successValueChange.subscribe(message =>{
      let booking=JSON.parse(Base64.decode(message));
      this.ticketDetails=booking.data.data;
      this.rawTicketDetails = booking;
      if(!message){
        message = localStorage.getItem('success-buupass')
        if(!message) {
         this.router.navigate(['/'])
       }
      }else{
        localStorage.setItem('success-buupass', message)
      }

       if(!message) {
         this.router.navigate(['/'])
       }
    });

  }

}
