import { Component, OnInit, Input, Output, EventEmitter, HostListener, forwardRef } from '@angular/core';
import { AbstractControl,ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {Observable,interval } from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import { DropdownStatusService } from 'src/app/services/dropdown-status.service';
import { FromStatusService } from 'src/app/services/from-status.service';
import { PassDepatureValueService } from 'src/app/services/pass-depature-value.service';
const noop = () => {
};


let topCities =[
  "Kampala",
  "Gulu",
  "Adjumani",
  "Moyo"
]

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FromComponent),
  multi: true
};
@Component({
  selector: 'app-from',
  templateUrl: './from.component.html',
  styleUrls: ['./from.component.css'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FromComponent implements  OnInit, ControlValueAccessor {

  @Input() options: Array<string>;
  @Input() selected: number;
  @Input() depature: string;
  @Input() className: string;
  @Input() placeholder: string;
  @Input() isReadOnly = false;
  @Output() optSelect = new EventEmitter();
  isOpen = false;
  selectedOption;
  location:string;
  control: AbstractControl | null;
  isSelected:boolean;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  isSelectedValue: boolean;
  key: string;
  isFocused: boolean;
  dataOptions:any;
  timer;
  cities:any;
  /**
   *Creates an instance of FromComponent.
   * @memberof FromComponent
   */
  constructor(private service: DropdownStatusService,
               private serviceFrom: FromStatusService,
               private getDepature: PassDepatureValueService,
    ){


this.getDepature.depatureValue.subscribe(message => {
  if(message)
    this.setValue(message);
    this.onChangeCallback(message);
    this.optSelect.emit(message);
});

    this.service.statusValue.subscribe(message => {
      if(message) this.isOpen = false
    });

    this.service.statusDateValue.subscribe(message => {
      if(message) this.isOpen = false
    });



  }
  ngOnInit() {
    // Place default value in dropdown
    if (this.selected) {
      this.placeholder = '';
      this.isOpen = false;
    }
    this.timer=interval(100).subscribe(x => { // will execute every 30 seconds
      this.assignValue();
    });
  }

  assignValue(){
    if(typeof this.options !== 'undefined' && this.options.length > 0){

      this.dataOptions =[...topCities, ...this.options];
    
      this.dataOptions = [...new Set(this.dataOptions)];      
      this.options=this.dataOptions;
      this.timer.unsubscribe();

    }
  }

  get value(): Array<String> {
    return this.options;
  }

  @HostListener('focus')
  focusHandler() {
    this.selected = 0;
    this.isFocused = true;
  }

  setValue(location:string) { this.location = location; }

  @HostListener('focusout')
  focusOutHandler() {
    this.isFocused = false;
  }

  @HostListener('document:keydown', ['$event'])
  keyPressHandle(event: KeyboardEvent) {
        //this.isFocused=this.isOpen
    if (this.isFocused) {
      this.key = event.code;

      switch (this.key) {
        case 'Space':
          this.isOpen = true;

          break;
        case 'ArrowDown':
          if (this.options.length - 1 > this.selected) {

            this.selected = this.selected + 1;
          }
          break;
        case 'ArrowUp':
          if (this.selected > 0) {
            this.selected = this.selected - 1;
          }
          break;
        case 'Enter':
          if (this.selected > 0) {
            this.isSelectedValue = true;

            this.isOpen = false;
            this.onChangeCallback(this.selected);
            this.optSelect.emit(this.options[this.selected]);

            //setValue(location:string)
          }
          break;
      }
    }

  }

  /**
  * option selection
  * @param {string} selectedOption - text
  * @param {number} idx - current index of item
  * @param {any} event - object
  */
  optionSelect(selectedOption: string, idx: number, e: any) {
    e.stopPropagation();
    this.selected = idx;
    this.setValue(this.options[this.selected]);
    this.isSelectedValue = true;
    this.isOpen = false;
    this.onChangeCallback(selectedOption);
    this.optSelect.emit(selectedOption);
    this.serviceFrom.changeValue(this.isOpen);
    this.serviceFrom.changeSelectedValue(this.options[this.selected]);

  }

  public setSelectedoptoion(selectedOption){
    this.onChangeCallback(selectedOption);
    this.optSelect.emit(selectedOption);
  }

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term =>
      {
        term.length < 1 && this.dataOptions.length > 0
      ?  this.options =this.dataOptions
      : this.options=this.options.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)

      })
  )

  /**
  * toggle the dropdown
  * @param {any} event object
  */
  toggle(e: any) {
    e.stopPropagation();
    this.setValue('');
    this.options =this.dataOptions
    // close all previously opened dropdowns, before open
    const allElems = document.querySelectorAll('.dropdown-wrapper');
    for (let i = 0; i < allElems.length; i++) {
      allElems[i].classList.remove('is-open');
    }
    this.isOpen = !this.isOpen;
    this.serviceFrom.changeValue(this.isOpen);
    if (this.selected >= 0) {

      // document.querySelector('#li' + this.selected).scrollIntoView(true);
    }
  }

  checkIfOpen(e: any){
    this.setValue('');
    this.options =this.dataOptions
    // close all previously opened dropdowns, before open
    const allElems = document.querySelectorAll('.dropdown-wrapper');
    for (let i = 0; i < allElems.length; i++) {
      allElems[i].classList.remove('is-open');
    }
    this.isOpen=true;

  }

  /**
  * dropdown click on outside
  */
  @HostListener('document: click', ['$event'])
  onClick() {

    this.isOpen = false;
  }

  /**
   * Method implemented from ControlValueAccessor and set default selected value
   * @param {*} obj
   * @memberof FromComponent
   */
  writeValue(obj: any): void {

    if (obj && obj !== '') {
      this.isSelectedValue = true;
      this.selected = obj;

    } else {
      this.isSelectedValue = false;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {

  }
  setIndex(index){
   this.selected = index;
   this.setValue(this.options[this.selected]);
  }

  resetIndex(){
   this.selected = -1;
   this.setValue(this.options[this.selected]);
}

}
