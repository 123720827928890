import { Component, ViewChild, ElementRef, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import {Destinations } from '../models/index'
import {HomeService,DataService } from '../services/index'
import { formatDate  } from '@angular/common';
import {Router} from "@angular/router"
import { SharedscrollstatusService } from '../services/sharedscrollstatus.service';
import { PassDestinationValueService} from '../services/pass-destination-value.service'
import { PassDepatureValueService} from '../services/pass-depature-value.service'
import { TownsService } from '../services/towns.service'
import { Base64 } from 'js-base64';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('2000ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('2000ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]

})
export class HomeComponent implements AfterViewInit  {
  bsInlineValue = new Date();
  reactiveForm: UntypedFormGroup;
  fromPlaceholder="From";
  toPlaceholder="To";
  destinationLoading=false;
  pickup='';
  dropoff='';
  traveldate='';
  daterange='';
  destinations: Destinations[] = [];
  destination: any = {};
  res: any = {};
  today=new Date();
  bsValue = new Date();
  public pickplaceholder: string = 'Depature';
  public destplaceholder: string = 'Destination';
  public historyHeading: string = 'Recently selected';
  keyword = 'name';
  isClicked=false;
  isPickupClicked=false;
  returning=false;
  isReadOnly=false;
  types= [];
  //traveldate:any;
  data = [];
  msg = '';
  formdata={};
  submitted = false;
  loading: boolean;
  form = new UntypedFormGroup({
    traveldate: new UntypedFormControl(new Date()),
  });

  defaultImage="assets/img/cities/placeholder.jpg";

  mombasa="assets/img/cities/mombasa.jpg";
  nairobi="assets/img/cities/nairobi.jpg";
  kisumu="assets/img/cities/kisumu.jpg";
  kampala="assets/img/cities/kampala.jpg";
  dar="assets/img/cities/dar.jpg";
  bungoma="assets/img/cities/bungoma.jpg";
  eldoret="assets/img/cities/eldoret.jpg";

  @ViewChild('stickyForm') menuElement: ElementRef;
  sticky: boolean = false;
  isOpen=false;
  elementPosition: any;
  constructor(
    private service : HomeService,
    private dataService : DataService,
    private router:Router,
    private _fb: UntypedFormBuilder,
    private townService:TownsService,
    private serviceScroll: SharedscrollstatusService,
    private serviceDestination: PassDestinationValueService,
    private serviceDepature:PassDepatureValueService,
    private cdRef: ChangeDetectorRef
        ) {
          this.reactiveForm = _fb.group({
            pickup:[null, Validators.required],
            dropoff:[null, Validators.required],
            daterange:[formatDate(new Date(),"yyyy-MM-dd","en")+':One Way',Validators.required],
            traveldate:[new Date(), Validators.required],
            returndate:[null, null]
          });
    }
  ngAfterViewInit(){
    localStorage.setItem('search', '');
    this.loadDestinations();
    this.formControlValueChanged();
    this.cdRef.detectChanges();
    let top = document.getElementById('tophome');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

  }

  onTravelDateChange = (event) =>{
    var traveldate = formatDate(event,"yyyy-MM-dd","en-KE");
    this.today = event;
  }

  @HostListener('window:scroll', ['$event'])
    handleScroll(event){
      const windowScroll = window.pageYOffset;
      if(windowScroll >= this.elementPosition){
        this.menuElement.nativeElement.setAttribute('style','position:fixed')
        this.sticky = true;
      } else {
        this.sticky = false;

      }
      this.serviceScroll.changeValue(this.sticky);
    }

     get f() { return this.reactiveForm.controls; }

     formControlValueChanged() {
      this.reactiveForm.get('pickup').valueChanges.subscribe(
          (mode: string) => {
              //this.serviceDepature.changeValue(mode);
          });

          this.reactiveForm.get('dropoff').valueChanges.subscribe(
            (mode: string) => {
              //this.serviceDestination.changeValue(mode);
            });
  }

  onFocused(e){
    this.isClicked=true;
  }

  returnClicked =() =>{
    this.returning=true;
    this.isOpen=true;
  }

  closeReturn(){
    this.returning=false;
  }

  onClosed(e){
    this.isClicked=false;
  }

  onPickupFocused(e){
    this.isClicked=true;
    this.isPickupClicked=true;

  }
  onPickupClosed(e){
    this.isClicked=false;
    this.isPickupClicked=false;
  }

  submitForm() {

    if(this.reactiveForm.value.returndate == null){
      this.returning =false;
      this.isOpen=false;
    }
      this.submitted = true;
      // stop here if form is invalid
      if (this.reactiveForm.invalid ) {
          return;
      }

      let value = this.reactiveForm.value;
      var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");
      var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");

      value.traveldate=traveldate;
      if(this.returning){
         value.returndate=returndate;
      }


    this.formdata={
      'pickup' : value.pickup,
      'dropoff' : value.dropoff,
      'pickupname' : value.pickup,
      'dropoffname' : value.dropoff,
      'traveldate' : traveldate,
      'returndate': returndate,
      'returning' : this.returning
    }

    const route = `${value.pickup}-to-${value.dropoff}`;  // e.g., "Nairobi-to-Mombasa"

    let queryParams = {
      fromCityName: value.pickup,
      toCityName: value.dropoff,
      onward: traveldate,
      ...(this.returning && { return: returndate })  // Add 'return' only if returning is true
    };

    this.router.navigate([`/booking/${route}`], { queryParams });

  }

  searchTrip(pickup: string, dropoff: string) {
    const tripDetails = {
      pickup: pickup,
      dropoff: dropoff,
      pickupname: pickup,
      dropoffname: dropoff,
      traveldate: formatDate(this.today, "yyyy-MM-dd", "en-KE"),
      returndate: '',
      returning: this.returning,
    };
  
    // Encode the trip details if required by your service
    const encodedTripDetails = Base64.encode(JSON.stringify(tripDetails));
    this.dataService.changeSearchMessage(encodedTripDetails);
  
    // Define the route dynamically based on pickup and dropoff
    const route = `${pickup.toLowerCase()}-to-${dropoff.toLowerCase()}`;
  
    const queryParams = {
      fromCityName: pickup,
      toCityName: dropoff,
      onward: tripDetails.traveldate,
      ...(this.returning && { returndate: tripDetails.returndate }),
    };
  
    // Navigate to the dynamic route with query parameters
    this.router.navigate([`/booking/${route}`], { queryParams });
  }
  


  private loadDestinations() {
    this.destinationLoading=true;

    this.service.getDestinations().subscribe(
      response => {
        let list: string[] = [];
        this.res = response;
          this.res.data.all_cities.forEach(element => {
              list.push(element.name);
          });
        this.data=list;
        this.townService.changeValue(this.res.data.all_cities);
        let cities=Base64.encode(JSON.stringify(this.res.data.all_cities));
        localStorage.setItem('cities', cities);
        this.types=this.res.data;
        this.destinationLoading=false;
      },
      error => {

      }
    );

  }

}
