
<div class=" hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg visible-md">
  <app-headerhome ></app-headerhome>
</div>
<div >
  <div class="MainView _pos-r">
    <div class=" hidden-md hidden-lg ">
        <app-search-form></app-search-form>
    </div>
      <div  class="theme-hero-area-body _mt-90 visible-lg visible-md _w-f buupass-container" >
            <div class="theme-search-area-tabs ">
              <div class="theme-search-area-tabs-header  _mr-20 mt-10">
                <div class="hidden-xs">
                <h1 class="  banner-text "> Book your long-distance travel in Uganda.</h1>
                <img class=" hidden-md hidden-lg _ml-50" src="assets/img/company1/logo-mobile.jpg" alt="buupass.com" alt="Image Alternative text" title="Image Title"/>
          </div>
              </div>
              <div class="tabbable " #stickyForm  [ngClass]="sticky ? 'sticky':'' " >
                <div class="tab-content   _bg-w tab-content-curved">
                  <div class="tab-pane active" id="SearchAreaTabs-4" >
                    <div class="theme-search-area " >
                        <form  autocomplete="off" novalidate [formGroup]="reactiveForm" (ngSubmit)="submitForm()">
                          <div class="row" >
                                <div class="col-md-3">
                                    <app-from formControlName="pickup"
                                      [options]="data"
                                      [ngClass]="{ 'ng-invalid-custom': submitted && f.pickup.errors }"
                                      [placeholder]="fromPlaceholder"
                                      [isReadOnly]="isReadOnly">
                                    </app-from>
                                    <span *ngIf="submitted && f.pickup.errors" [@slideInOut] class="invalid-select">Please enter depature location
                                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div class="col-md-3">
                                    <app-dropdown
                                      formControlName="dropoff"
                                      [dropdownoptions]="data"
                                      [ngClass]="{ 'ng-invalid-custom': submitted && f.dropoff.errors }"
                                      [placeholder]="toPlaceholder"
                                      [isReadOnly]="isReadOnly">
                                    </app-dropdown>
                                    <span *ngIf="submitted && f.dropoff.errors" [@slideInOut] class="invalid-select">Please enter arrival location
                                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div class="col-md-3">
                                  <div class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white  theme-search-area-section-mr">
                                    <div class="theme-search-area-section-inner">
                                      <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>
                                      <input type="text"
                                      formControlName="traveldate"
                                      placeholder="Travel Date"
                                      [minDate]="bsValue"
                                      (bsValueChange)="onTravelDateChange($event)"
                                      class="theme-search-area-section-input "
                                      bsDatepicker
                                      [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                                      >
                                    </div>
                                  </div>
                                </div>
                                <!-- <div class="col-md-2 ">
                                  <div class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white">
                                    <div class="theme-search-area-section-inner theme-search-area-section-inner-return">

                                              <div *ngIf="returning" class="theme-search-area-section-inner returndate">
                                                <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>

                                                <i [ngClass]="( isClicked ) ? 'hide-returnicon':''" class="theme-search-area-section-icon lin lin-calendar "></i>
                                                <input  autocomplete="off" type="text"
                                                placeholder="DD-MM-YYYY"
                                                class="theme-search-area-section-input"
                                                formControlName="returndate"
                                                [minDate]="today"
                                                #dp="bsDatepicker"
                                                [isOpen]="isOpen"
                                                bsDatepicker
                                                [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                                                >

                                                <i (click)="closeReturn()" [ngClass]="( isClicked ) ? 'hide-returnicon':''"  class="times-icon  fa fa-times"></i>
                                        </div>
                                        <button *ngIf="!returning" (click)="returnClicked()" class="flix-btn-arrow  DatePlaceholder__button___3XZ5p" type="button"> <i class=" icon-flix fa fa-plus "></i> Add Return
                                        </button>
                                    </div>
                                  </div>
                                </div> -->
                                <div class="col-md-3">
                                  <button type="submit"  class="theme-search-area-submit btn-block theme-search-area-submit-curved  theme-search-area-submit-glow">SEARCH</button>
                                </div>
                        </div>
                        </form>
                  </div>
                  <div class="text-center _mt-15 _mb-10" >
                    <img  height="30" alt="" class="text-center"  src="assets/img/brands/pay-via.png">
                </div>
                </div>
              </div>
              </div>
          </div>
      </div>
       <div class="theme-page-section _mt-10 theme-page-section-sm ">
        <div class="buupass-home-section">
          <div class="buupass-container">
            <div class="row">
              <div class="col-md-12 col-xs-12">
                <div  class="xmas-img-web _mt-20   _mob-h" style="">
                  <img  src="assets/img/offers/desktopbanner.webp">
                </div>
                <div  class="xmas-img-mobile  _mt-10  _desk-h" style="">
                  <img class="img-fluid" width="100%" src="assets/img/offers/mobilebanner.webp">
                </div> 
              </div>
          </div>
        </div>
        </div>
      </div> 
      <div class="theme-page-section visible-md visible-lg _mt-10 theme-page-section-sm ">
          <div class="theme-page-section-header theme-page-section-header-white">
            <div class="buupass-home-section">
            <div class="buupass-container">
                <div class="feature-list">
                    <div class="list-item">
                      <div class="row">
                          <div class="col-md-2 col-xs-2">
                              <img src="assets/img/company1/payments.png" class="_mt-20" style="height: 45px;"/>
                          </div>
                          <div class="col-md-8 col-xs-8">
                            <h5 class="font-desc">Flexible payments</h5>
                            <p class="content">Pay with MTN MoMo,Airtel Money, credit cards or bank transfers. We have you fully covered.</p>
                          </div>
                     </div>
                    </div>
                    <div class="list-item">
                      <div class="row">
                          <div class="col-md-2 col-xs-2">
                              <img src="assets/img/company1/support.png" class="_mt-20" style="height: 45px;"/>
                          </div>
                          <div class="col-md-8 col-xs-8">
                            <h5 class="font-desc">Great customer care</h5>
                            <p class="content">Get excellent customer service available 8:00 AM to 10:00 PM, via phone, chat or email.</p>
                          </div>
                     </div>
                    </div>
                    <div class="list-item">
                      <div class="row">
                          <div class="col-md-2 col-xs-2">
                              <img src="assets/img/company1/convenience.png" class="_mt-20" style="height: 45px;"/>
                          </div>
                          <div class="col-md-8 col-xs-8">
                            <h5 class="font-desc">Enjoy convenience</h5>
                            <p class="content">Book anytime from the convenience of your school, office, home or market.</p>
                          </div>
                     </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    <div class="theme-hero-area">
        <div class="theme-hero-area-body">
          <div class="theme-page-section-buupass ">
            <div class="buupass-container">
              <div class="wa_0_1pykh">
                <div class="">
                    <div class="_mt-30 wf_bn_1op5D wf_w7_3_zC4 wf_x4_TJbnM">
                        <h3 class="q5_bc_2ozg0">
                          <span>These bus operators trust us.</span> <span>We sell their tickets all in one
                                place.
                          </span>
                        </h3>
                    </div>
                    <div class="wf_bn_1op5D wf_w7_3_zC4 wf_yc_2N4u5 visible-md visible-lg">
                        <div class="q5_q9_28ZkV">
                            <div class="q5_rb_VniSv"><img src="assets/img/images/megabus.webp"
                                    alt="Mega Bus" height="70" loading="lazy"></div>
                            <div class="q5_rb_VniSv"><img src="assets/img/images/divine.webp"
                                      alt="Devine Coach" height="70" loading="lazy"></div>
                            <div class="q5_rb_VniSv"><img src="assets/img/images/moderncoast.webp"
                                        alt="Easy Coach" height="50" loading="lazy"></div>
                            <div class="q5_rb_VniSv"><img src="assets/img/images/easy_coach.webp"
                                          alt="Modern Coast" height="40" loading="lazy"></div>
                            <div class="q5_rb_VniSv"><img src="assets/img/images/greenline.webp"
                                            alt="Modern Coast" height="60" loading="lazy"></div>
                            <div class="q5_rb_VniSv"><img src="assets/img/images/mashpoa.webp"
                                              alt="Mashpoa"  loading="lazy"></div>


                        </div>
                        
                    </div>
                    <div class=" hidden-md hidden-lg ">
                      <div class="wf_bn_1op5D wf_w7_3_zC4 wf_yc_2N4u5 ">
                          <div class="q5_q9_28ZkV">
                              <div class="q5_rb_VniSv"><img src="assets/img/images/megabus.webp"
                                      alt="Easy Coach" height="100" loading="lazy"></div>
                              <div class="q5_rb_VniSv"><img src="assets/img/images/divine.webp"
                                        alt="Modern Coast" height="100" loading="lazy"></div>
                              <div class="q5_rb_VniSv"><img src="assets/img/images/moderncoast.webp"
                                          alt="Easy Coach" height="50" loading="lazy"></div>
                              <div class="q5_rb_VniSv"><img src="assets/img/images/easy_coach.webp"
                                            alt="Modern Coast" height="40" loading="lazy"></div>
                              <div class="q5_rb_VniSv"><img src="assets/img/images/greenline.webp"
                                              alt="Modern Coast" height="60" loading="lazy"></div>
                          </div>
                     

                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="row theme-page-section-download _p-20 hidden-md hidden-lg" data-gutter="0">
      <div class="col-12 col-md-6 mx-auto text-center">
        <div class="banner">
          <h2 class="theme-mobile-app-title">Download our app today</h2>
          <p class="theme-mobile-app-subtitle">
            Book and manage your trips on the go. Be notified of our hot deals and offers.
          </p>
          <img 
            alt="Download mobile app" 
            src="assets/img/company1/mobile_1.png" 
            class="image-app" 
          />
          <a class="banner-link" href="https://play.google.com/store/apps/details?id=io.andronicus.buupass&hl=en_SG"></a>
          <ul class="theme-mobile-app-btn-list mt-3">
            <li>
              <a
                class="btn btn-dark theme-mobile-app-btn p-3"
                href="https://play.google.com/store/apps/details?id=io.andronicus.buupass&hl=en_IN&gl=US&pli=1"
              >
                <i class="theme-mobile-app-logo">
                  <img 
                    src="assets/img/brands/play-market.png" 
                    alt="Play Store" 
                    title="Download from Play Store"
                  />
                </i>
                <span>Download on<br /><span>Play Store</span></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
      <div class="theme-hero-area _mt-10">
        <div class="theme-hero-area-body">
          <div class="theme-page-section theme-page-section-buupass theme-page-section">
            <div class="buupass-container">
              <div class="theme-page-section-header _ta-l">
                <h2 class="destinationHeading">Top Destinations</h2>
                <p class="desSubHeading">These destinations are popular among travelers like you</p>
              </div>
              <div class="row">
                  <div class="col-md-12">
                    <div class="row row-col-gap" data-gutter="10">
                      <div (click)="searchTrip('Kampala', 'Gulu')" class="col-md-5 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/kampala.jpg);"></div>
                          <div class="banner-mask"></div>
                          <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Gulu&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                                <h5 class="banner-title">Kampala</h5>
                                <p class="banner-subtitle">Uganda</p>
                          </div>
                        </div>
                      </div>
                      <div (click)="searchTrip('Kampala', 'Arua')" class="col-md-3 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/arua.webp);"></div>
                          <div class="banner-mask"></div>
                          <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Kampala&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                                <h5 class="banner-title">Arua</h5>
                                <p class="banner-subtitle">Uganda</p>
                          </div>
                        </div>
                      </div>
                      <div (click)="searchTrip('Kampala', 'Adjumani')" class="col-md-4 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/adjumani.webp);"></div>
                          <div class="banner-mask"></div>

                          <div class="banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Kampala&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                            <h5 class="banner-title">Adjumani</h5>
                            <p class="banner-subtitle">Uganda</p>
                          </div>
                        </div>
                      </div>
                      <div (click)="searchTrip('Kampala', 'Moyo')" class="col-md-6 change-city">
                        <div class="banner _h-33vh _br-3 banner-animate banner-animate-mask-in">
                          <div class="banner-bg" style="background-image:url(assets/img/cities/v2images/moyo.webp);"></div>
                          <div class="banner-mask"></div>
                          <div class="  banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                            <h3 class="PictureCardstyled__OverlayTextWrapper-au8d2z-12 gPDuIT ePbYQC">
                               <div aria-hidden="true" class="PictureCardstyled__PictureCardCityName-au8d2z-11 kRWBRX">
                                  <div class="Heading__StyledHeading-sc-1b8cso5-0 gZHeZX">Kampala&nbsp;</div>
                               </div>
                                <svg class="Icon__StyledIcon-sc-1pnzn3g-0 arrow_home" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"><path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path></svg>
                            </h3>
                                <h5 class="banner-title">Moyo</h5>
                                <p class="banner-subtitle">Uganda</p>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
            </div>
            <div class="theme-page-section theme-page-section-sm visible-lg visible-md">
              <div class="buupass-container">
                <div class="theme-page-section-header theme-page-section-header-white">
                  <div class="buupass-home-section">
                    <div class="feature-list">

                        <div class="list-item">
                          <div class="row">

                              <div class="col-md-8 col-xs-8">
                                  <h5 class="text-center">Customers</h5>
                                <h2 class=" text-center text-pink text-stats">400K+</h2>
                                <p class="text-center">Total customers using Bus feature.</p>
                              </div>
                         </div>
                        </div>
                        <div class="list-item">
                          <div class="row">
                            <div class="col-md-8 col-xs-8">
                                <h5 class="text-center">Buses</h5>
                              <h2 class=" text-center text-pink text-stats">420+</h2>
                              <p class="text-center">Total buses in operations</p>
                            </div>
                         </div>
                        </div>
                        <div class="list-item">
                          <div class="row">
                            <div class="col-md-8 col-xs-8">
                                <h5 class="text-center">Routes</h5>
                              <h2 class=" text-center text-pink text-stats">115+</h2>
                              <p class="text-center">Total routes covered </p>
                            </div>
                         </div>
                        </div>
                        <div class="list-item">
                          <div class="row">
                            <div class="col-md-8 col-xs-8">
                                <h5 class="text-center">Tickets</h5>
                              <h2 class=" text-center text-pink text-stats">7M+</h2>
                              <p class="text-center">Bus tickets sold</p>
                            </div>
                         </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div  class="theme-hero-area theme-page-section theme-page-section-sm theme-page-section-offers visible-md visible-lg">
      <div class="theme-hero-area-body">
        <div class="container">
          <div class="theme-page-section _p-0">
            <div class="row">
              <div class="col-md-10 col-md-offset-1">
                <div class="theme-mobile-app">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="theme-mobile-app-section">
                        <img alt="Download mobile app" src="assets/img/company1/mobile_1.png" style="height:450px;"  class="image-app" alt="">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="theme-mobile-app-section">
                        <div class="theme-mobile-app-body">
                          <div class="theme-mobile-app-header">
                            <h2 class="theme-mobile-app-title">Download our app</h2>
                            <p class="theme-mobile-app-subtitle">Book and manage your trips on the go. Be notified of our hot deals and offers.</p>
                          </div>
                          <ul class="theme-mobile-app-btn-list">

                            <li>
                              <a class="btn btn-dark theme-mobile-app-btn _p-5" href="https://play.google.com/store/apps/details?id=io.andronicus.buupass&hl=en_IN&gl=US&pli=1" >
                                <i class="theme-mobile-app-logo">
                                  <img src="assets/img/brands/play-market.png" alt="Image Alternative text" title="Image Title">
                                </i>
                                <span>Download on
                                  <br>
                                  <span>Play Store</span>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>

  <app-footer></app-footer>
