import { Component, OnInit } from '@angular/core';
import { formatDate, PlatformLocation  } from '@angular/common';
import {Router} from "@angular/router"
import { DataService } from '../../services/data.service';
import { Base64 } from 'js-base64';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  today=new Date();
  returning = false;

  constructor(
    private router:Router,
    private dataService: DataService
  ) { }

  ngOnInit() {
  }


  searchTrip(pickup: string, dropoff: string) {
    const tripDetails = {
      pickup,
      dropoff,
      pickupname: pickup,
      dropoffname: dropoff,
      traveldate: formatDate(this.today, 'yyyy-MM-dd', 'en-KE'),
      returndate: '',
      returning: this.returning
    };

    const encodedTripDetails = Base64.encode(JSON.stringify(tripDetails));
    this.dataService.changeSearchMessage(encodedTripDetails);

    const route = `${pickup.toLowerCase()}-to-${dropoff.toLowerCase()}`;
    const queryParams = {
      fromCityName: pickup,
      toCityName: dropoff,
      onward: tripDetails.traveldate,
      ...(this.returning && { returndate: tripDetails.returndate })
    };

    this.router.navigate([`/booking/${route}`], { queryParams });
  }
}